<template>
	<div class="outer-wrapper">

		<div class="inner-wrapper" >

			<div class="heading-section">
				<h1 class="heading3">My Trips</h1>
			</div>

			<div class="trip-categories" v-if="!loading && !error">
				<!-- Icons/buttons here for changing section -->
				<!-- <div class="btn past-btn">
					<img class="inactive" src="@/assets/trips/past.png" />
					<img class="active" src="@/assets/trips/past-blue.png" />
					<div class="btns">
						Past
					</div>
				</div>
				<div class="btn booked-btn">
					<img class="inactive" src="@/assets/trips/booked.png" />
					<img class="active" src="@/assets/trips/booked-blue.png" />
					<div class="btns">
						Booked
					</div>
				</div>
				<div class="btn saved-btn">
					<img class="inactive" src="@/assets/trips/saved.png" />
					<img class="active" src="@/assets/trips/saved-blue.png" />
					<div class="btns">
						Saved
					</div>
				</div> -->
				<div class="btn create-btn" @click="create()">
					<img class="plus" src="@/assets/trips/plus.png" />
					<div class="btns">
						Create
					</div>
				</div>
			</div>

			<Loader :loading="loading" :error="error" @refresh="init"/>

			<!-- For now, just saved trips -->
			<div v-if="!loading && !error">
				<div class="saved-trips" v-if="account.status != null && account.status.loggedIn != false">
					<div class="container">
						<!-- Backend Trips (user is logged in, and these trips are stored in the backend) -->
						<div class="no-trips" v-if="basket.backendTrips.length == 0">
							<div class="text">
								<div>You do not have any upcoming trips</div>
								<span>Create your own &amp; share with friends.</span>
							</div>
							<div class="select-search">
								<SearchTypeSelect />
							</div>
						</div>
						
						<div v-for="trip in basket.backendTrips" :key="trip.id" @click="viewTrip(trip.id)" class="trip-container">
							<div class="trip-container-outer">
								<div class="trip-container-inner" :style="[ trip.trip_data.data != null && trip.trip_data.data.image ? {'background-image': `url(${trip.trip_data.data.image})`, 'background-size': 'cover' } : {}]">
									<div class="inner">
										<!-- <a class="upload" href="">
											<img class="upload" src="@/assets/trips/upload.png" alt="upload"/>
										</a> -->
										<span class="trip-name">
											<div class="trip-category">Trip</div>
											<div class="name">{{ trip.trip_data.data == null || trip.trip_data.data.length == 0 ? "Your Trip!" : trip.trip_data.data.name }}</div>
										</span>
									</div>
								</div>
							</div>
							<!-- <ul class="notifications">
								<li class="notifications-inner green">
									<span>
										Your next flight is on <b>May 13, 2022</b>
									</span>
								</li>
								<li class="notifications-inner yellow">
									<span>
										Your hotel accommodation <b>Antalya Mega Club Holiday Resort</b>
									</span>
								</li>
								<li class="notifications-inner red">
									<span>
										Rock Climbing activity added / <b>Book Now!</b>
									</span>
								</li>
							</ul> -->
						</div>
					</div>
				</div>

				<div class="saved-trips" v-else> <!-- If the user is NOT logged in - only show default trips (and some message?) -->
					<div v-for="trip in basket.trips" :key="trip.name" class="container">
						Please log in to see My Trips
						<div><router-link to="/login?redirect=my-trips">Log In</router-link></div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<style scoped>
	.header {
		display: block;
		text-align: center;
		padding-top: 25px;
	}
	.saved-trips .container {
		margin: 0 auto;
		text-align: center;
		margin-bottom: 14px;
	}
	.saved-trips .no-trips .text {
		font-size: 14px;
		color: #748087; 
	}
	.saved-trips .no-trips .text div {
		font-weight: 700;
		color: #748087;
	}
	.saved-trips .select-search {
		margin-top: 70px;
	}
	.saved-trips .trip-container {
		margin: 24px auto;
		margin-bottom: 30px;
		height: auto;
		max-width: 320px;
	}
	.saved-trips .trip-container-outer {
		border-radius: 16px;
		background: linear-gradient(0deg, #A4BCC4 0%, #D7DFE1 100%);
		margin-bottom: 16px;
		width: 100%;
		height: 150px;
		margin: 0 auto;
		overflow: hidden;
	}
	.saved-trips .trip-container-inner {
		position: relative;
		background-size: 129px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('../../assets/trip/purpose-logo.png');
		height: 100%;
	}
	.saved-trips .trip-container-inner .inner {
		height: 100%;
		background: linear-gradient(180deg, rgba(0, 39, 42, 0) 65%,  rgba(0, 39, 42, 0.9) 100%);
	}
	.saved-trips .trip-container-inner:hover, .saved-trips .trip-container .upload:hover  {
		cursor: pointer;
	}
	.saved-trips .trip-container-inner img.upload {
		position: absolute;
		right: 21px;
		top: 17px;
	}
	.saved-trips .trip-name {
		position: absolute;
		bottom: 15px;
		left: 15px;
		font-size: 18px;
		font-weight: 700;
		color: #ffffff;
		text-align: left;
	}
	.saved-trips .trip-name .name {
		display: block;
	}
	.saved-trips .trip-name .trip-category {
		display: inline-block;
		font-weight: 700;
		font-size: 11px;
		line-height: 13px;
		color: white;
		background-color: #118AB2;
		border-radius: 9px;
		padding: 2px 5px;
		text-transform: uppercase;
		margin-bottom: 3px;
	}
	.saved-trips .btn {
		height: 30px;
		line-height: 30px;
		width: 150px;
		background-color: #118AB2;
		display: block;
		margin: 20px auto;
	}
</style>
<script>
import { mapState, mapActions } from 'vuex'
import Menu from '@/components/Menu.vue'
import TopLeftIcon from '@/components/TopLeftIcon.vue'
import router from '@/router';
import helpers from '@/helpers/helpers.js';
import { tripService } from '@/services/tripService.js';
import Loader from '@/components/Loader'
import SearchTypeSelect from "@/components/SearchTypeSelect.vue";

export default {
	data() {
		return {
			trip_bg: {
				img: "trips/barcelona-sml",
			},
			loading: false,
			error: false,
		}
	},
	components: {
		Menu,
		TopLeftIcon,
		Loader,
		SearchTypeSelect,
	},
	computed: {
		...mapState({
			basket: state => state.basket,
			account: state => state.account,
			alert: state => state.alert,
		}),
	},
	methods: {
		...mapActions({
			alertError: "alert/error",
			alertSuccess: "alert/success",
			fetchTrips: "basket/fetchTrips",
		}),
		getImage: (img) => helpers.getImage(img),
		create() {
			if(!this.loading) { // Prevent spam clicks
				this.loading = true;
				tripService.createTrip()
					.then(
						response => {
							var trip_id = response.data.data.id;
							this.alertSuccess("Trip created!");
							if(trip_id) {
								router.push({ 
									name: 'Trip',
									params: {
										trip_id: trip_id,
									}
								})
							}
							this.loading = false;
						},
						error => {
							this.alertError(error);
							this.error = error;
							this.loading = false;
						}
					);
			}
			
		},
		viewTrip(trip_id) {
			router.push('/trip/' + encodeURIComponent(trip_id)); // TripID + TripItemID
		},
		init() {
			if(this.basket.backendTrips.length == 0) {
				this.loading = true;
			}
			this.fetchTrips()
				.then(response => {
					this.loading = false;
					this.error = false;
				},
				error => {
					this.alertError(error);
					this.error = error;
					this.loading = false;
				});
		}
	},
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				route: this.$route.query.redirect ? this.$route.query.redirect : { name: 'home' },
			},
			right: {
				type: 'menu',
			},
		});

		this.init();
	}
};
</script>